import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import ResponsiveImage from "~/components/responsive-image";
import useFetchCourse from "~/hooks/use-fetch-course";
import styles from './package-bundle.module.css';

const courseIds = [
    'holistic-singer-videos',
    'holistic-singers-guide-to-breathing',
    'holisticsinger-audio-warmups',
    'just-breathe'
];

const CourseBundlePage: React.FC = () => {
    const { channelId, courseId } = useParams<{ channelId: string, courseId: string }>();
    const [coursesData, setCoursesData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [fetchCourseError, setFetchCourseError] = useState<string | null>(null);

    const fetchCourse1 = useFetchCourse(channelId, courseIds[0]);
    const fetchCourse2 = useFetchCourse(channelId, courseIds[1]);
    const fetchCourse3 = useFetchCourse(channelId, courseIds[2]);
    const fetchCourse4 = useFetchCourse(channelId, courseIds[3]);

    console.log(fetchCourse1, fetchCourse2, fetchCourse3, fetchCourse4);
    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const fetchedCourses = [
                    fetchCourse1.courseData,
                    fetchCourse2.courseData,
                    fetchCourse3.courseData,
                    fetchCourse4.courseData
                ];
                setCoursesData(fetchedCourses);
            } catch (error) {
                if (error instanceof Error) {
                    setFetchCourseError(error.message);
                } else {
                    setFetchCourseError('An unknown error occurred');
                }
            } finally {
                setLoading(false);
            }
        };
        fetchCourses();
    }, [fetchCourse1.courseData, fetchCourse2.courseData, fetchCourse3.courseData, fetchCourse4.courseData]);

    if (loading) return <div>Loading...</div>;
    if (fetchCourseError) return <div>Error: {fetchCourseError}</div>;

    return (
        <>
            <h3>THIS PACKAGE INCLUDES:</h3>
            <br />
            {channelId === 'fiercevoiceacademy' && courseId === 'holisticsinger' &&
                <section className={styles.courses}>
                    {coursesData.map((course, index) => (
                        <div key={index} className={styles.imageContainer}>
                            {course?.courseId && course?.title && (
                                <ResponsiveImage
                                    basePath="/course-info/fiercevoiceacademy"
                                    src={course.courseId}
                                    alt={course.title}
                                />
                            )}
                            <div>
                                {course?.title && <h3>{course.title}</h3>}
                                <div>{course?.bundleOfferText}</div>
                            </div>
                        </div>
                    ))}
                </section>
            }
        </>
    );
};

export default CourseBundlePage;