import { useQuery } from 'react-query';
import axios from 'axios';
import Course from '~/model/course';

const fetchCourseDetails = async (channelId: string, courseId: string) => {
    const response = await axios.get(`/api/content-creator/${channelId}/course/${courseId}`);
    return response.data;
};

const useFetchCourseDetails = (channelId: string | null, courseId: string | null) => {
    return useQuery<Course, Error>(
        ['courseDetails', channelId, courseId],
        () => fetchCourseDetails(channelId!, courseId!),
        {
            enabled: !!channelId && !!courseId,
        }
    );
};

export default useFetchCourseDetails;