import { useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import useVideoProgressStore from './stores/video-progress-store';

interface VideoPlayerProps {
    channelId: string;
    courseId: string;
    videoId: string;
    onEnd: (props: { totalLength: number; progress: number }) => void;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ channelId, courseId, videoId, onEnd }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const playerRef = useRef<any>(null); // Reference to the videojs player instance
    const previousVideoRef = useRef<string | null>(null);
    const userPausedRef = useRef(false);
    const location = useLocation();

    const { setVideoProgress, videoProgress} = useVideoProgressStore({ channelId, courseId });

    const startProgress = videoProgress[videoId]?.progress || 0;
    const totalLength = videoProgress[videoId]?.totalLength || 0;

    const handleVisibilityChange = useCallback(() => {
        if (
            document.visibilityState === 'visible' &&
            playerRef.current &&
            !userPausedRef.current
        ) {
            try {
                playerRef.current.play();
            } catch (error) {
                console.warn('Failed to resume video playback on visibility change.', error);
            }
        }
    }, []);

    useEffect(() => {
        // Save progress for the previous video if the video changes
        if (previousVideoRef.current && previousVideoRef.current !== videoId) {
            const previousVideo = previousVideoRef.current;
            const progress = playerRef.current?.currentTime() || 0;
            const totalLength = playerRef.current?.duration() || 0;
            setVideoProgress({videoId: previousVideo, progress, totalLength});
        }
        previousVideoRef.current = videoId;

        if (videoRef.current) {
            if (!playerRef.current) {
                // Initialize video.js
                // @ts-ignore
                const player = videojs(videoRef.current, {
                    controls: true,
                    autoplay: true,
                    preload: 'auto',
                    fluid: true,
                    playsinline: true,
                    playbackRates: [0.5, 1, 1.5, 2, 2.5, 3],
                    controlBar: {
                        children: [
                            'playToggle',
                            'volumePanel',
                            'currentTimeDisplay',
                            'progressControl',
                            'durationDisplay',
                            'playbackRateMenuButton',
                            'fullscreenToggle'
                        ]
                    }
                });

                player.hlsQualitySelector({
                    displayCurrentQuality: true,
                });

                playerRef.current = player;
            }

            const player = playerRef.current;

            const source = `/api/video-streams/playlist/${channelId}/${courseId}/${videoId}/main.m3u8`;

            if (player.src() !== source) {
                player.src({
                    src: source,
                    type: 'application/x-mpegURL',
                    withCredentials: true,
                });

                player.ready(() => {
                    if (totalLength && startProgress) {
                        player.currentTime(totalLength > startProgress ? startProgress : 0);
                    }
                });
            }

            const handlePlay = () => {
                const progress = player.currentTime();
                const totalLength = player.duration();
                setVideoProgress({videoId, progress, totalLength});
            };

            const handlePause = () => {
                userPausedRef.current = true;
                const progress = player.currentTime();
                const totalLength = player.duration();
                setVideoProgress({videoId, progress, totalLength});
            };

            const handleEnd = () => {
                const progress = player.currentTime();
                const totalLength = player.duration();
                setVideoProgress({videoId, progress, totalLength});
                onEnd({ progress, totalLength });
            };

            const handleSeeking = () => {
                const progress = player.currentTime();
                const totalLength = player.duration();
                setVideoProgress({videoId, progress, totalLength});
            };

            player.on('play', handlePlay);
            player.on('pause', handlePause);
            player.on('ended', handleEnd);
            player.on('seeking', handleSeeking);

            return () => {
                player.off('play', handlePlay);
                player.off('pause', handlePause);
                player.off('ended', handleEnd);
                player.off('seeking', handleSeeking);
            };
        }
    }, [
        channelId,
        courseId,
        videoId,
        setVideoProgress,
        startProgress,
        totalLength,
        onEnd,
        handleVisibilityChange,
        location.search,
    ]);

    return (
        <div className="video-container">
            <video ref={videoRef}
                   className="embed-responsive-item video-js vjs-default-skin"
                   playsInline
            />
        </div>
    );
};

export default VideoPlayer;