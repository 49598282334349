import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Container, Row, Col, Spinner } from 'react-bootstrap';
import { useUpdateCourse } from './hooks/use-update-course';
import useFetchCourseDetails from './hooks/use-fetch-course-details';
import { useFetchCourses } from './hooks/use-fetch-courses';
import { useChannelStore } from '~/stores/channel-store';
import editPageStyles from "./edit-course-page.module.css";
import styles from "../../styles/container.module.css"
import Course from "~/model/course";

const EditCoursePage: React.FC = () => {
    const { currentChannel: channelId } = useChannelStore();
    const [selectedCourseId, setSelectedCourseId] = useState<string | null>(null);
    const [formData, setFormData] = useState<Course>({
        channelId: '',
        courseId: '',
        showAsTopLevel: false,
        title: '',
        description: '',
        price: 0,
        bundleOfferText: ''
    });
    const [initialFormData, setInitialFormData] = useState<Course>(formData);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { data: courses, isLoading: isCoursesLoading, isError: isCoursesError, refetch: refetchCourses } = useFetchCourses(channelId ?? '');
    const selectedCourse = courses?.find(course => course.courseId === selectedCourseId);
    const { data: course, isLoading: isCourseLoading, isError: isCourseError } = useFetchCourseDetails(channelId, selectedCourse ? selectedCourseId : null);
    const { mutate: updateCourse } = useUpdateCourse();

    useEffect(() => {
        if (course) {
            const newFormData = {
                channelId: course.channelId,
                courseId: course.courseId,
                showAsTopLevel: course.showAsTopLevel,
                title: course.title,
                description: course.description,
                price: course.price,
                bundleOfferText: course.bundleOfferText
            };
            setFormData(newFormData);
            setInitialFormData(newFormData);
        }
    }, [course]);

    useEffect(() => {
        setFormData({
            channelId: '',
            courseId: '',
            showAsTopLevel: false,
            title: '',
            description: '',
            price: 0,
            bundleOfferText: ''
        });
        setSelectedCourseId(null);
    }, [channelId]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!channelId || !selectedCourseId) {
            setErrorMessage('Channel ID or Course ID is missing.');
            return;
        }

        const changedValues = Object.keys(formData).reduce((acc, key) => {
            if ((formData as any)[key] !== (initialFormData as any)[key]) {
                (acc as any)[key] = (formData as any)[key];
            }
            return acc;
        }, {} as Partial<Course>);

        if (Object.keys(changedValues).length === 0) {
            setErrorMessage('No changes to submit.');
            return;
        }

        setIsSubmitting(true);
        const changedCourse = {channelId, courseId: selectedCourseId, ...changedValues};
        updateCourse( changedCourse,
            {
                onSuccess: () => {
                    setSuccessMessage('Course updated successfully!');
                    setErrorMessage('');
                    refetchCourses().then(() => {
                        setSelectedCourseId(selectedCourseId);
                    });
                    setIsSubmitting(false);
                },
                onError: () => {
                    setErrorMessage('Failed to update course. Please try again.');
                    setSuccessMessage('');
                    setIsSubmitting(false);
                }
            }
        );
    };

    return (
        <Container className={`mt-5 mb-5 pt-3 ${styles.container}`}>
            <Row className="justify-content-md-center">
                <Col xs={12} lg={10}>
                    <h1>Edit Course</h1>
                    {isCoursesLoading && <p>Loading courses...</p>}
                    {isCoursesError && <p>Error loading courses.</p>}
                    {courses && (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId='courseSelect'>
                                <Form.Label>Select Course</Form.Label>
                                <Form.Control
                                    as='select'
                                    value={selectedCourseId || ''}
                                    onChange={(e) => setSelectedCourseId(e.target.value)}
                                >
                                    <option value='' disabled>Select a course</option>
                                    {courses.map(course => (
                                        <option key={course.courseId} value={course.courseId}>
                                            {course.title}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            {isCourseLoading && <p>Loading course details...</p>}
                            {isCourseError && <p>Error loading course details.</p>}

                            <Form.Group className="mb-3" controlId='title'>
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    type='text'
                                    value={formData.title}
                                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId='description'>
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as='textarea'
                                    value={formData.description}
                                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                />
                            </Form.Group>

                            <Form.Group className={`mb-3 ${editPageStyles.customCheckbox}`} controlId='showAsTopLevel'>
                                <Form.Check
                                    type='checkbox'
                                    label='Show In Catalog'
                                    checked={formData.showAsTopLevel}
                                    onChange={(e) => setFormData({ ...formData, showAsTopLevel: e.target.checked })}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId='bundleOfferText'>
                                <Form.Label>Bundle Offer Text</Form.Label>
                                <Form.Control
                                    type='text'
                                    value={formData.bundleOfferText}
                                    onChange={(e) => setFormData({ ...formData, bundleOfferText: e.target.value })}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId='price'>
                                <Form.Label>Price</Form.Label>
                                <Form.Control
                                    type='number'
                                    value={formData.price}
                                    onChange={(e) => setFormData({ ...formData, price: parseFloat(e.target.value) })}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Button variant='primary' type='submit' disabled={isSubmitting}>
                                    {isSubmitting ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Update Course'}
                                </Button>
                            </Form.Group>

                            {successMessage && <Alert variant="success">{successMessage}</Alert>}
                            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                        </Form>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default EditCoursePage;