import { useQuery } from 'react-query';
import axios from 'axios';

interface Course {
    courseId: string;
    title: string;
}

export function useFetchCourses(channelId: string) {
    return useQuery<Course[], Error>(['courses', channelId], async () => {
        const { data } = await axios.get(`/api/content-creator/${channelId}/course`);
        return data;
    });
}